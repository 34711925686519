import { uuid4 } from '@sentry/utils';
import { useState } from 'react';

import { type TitleBlockV2 } from '@lp-lib/game';
import { teamIntroOpeningScriptSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/teamIntroOpeningScript';
import { type TeamIntroOpeningScriptVariable } from '@lp-lib/shared-schema/src/ai/variables/types/teamIntroOpeningScript';

import { apiService } from '../../../../services/api-service';
import { isFetchErrorCode } from '../../../../services/public';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { err2s } from '../../../../utils/common';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../utils/media';
import { TagQuery } from '../../../../utils/TagQuery';
import { MultilineCardTTSEditor } from '../../../VoiceOver/MultilineCardTTSEditor';
import { useEditor } from '../../Blocks/Common/Editor/EditorUtilities';
import {
  TTSScriptsUpdater,
  useTTSScriptsUpdater,
} from '../../Blocks/TitleV2/TitleBlockTTSScriptsUpdater';
import { makeExampleVariableRegistrySnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import { UGCActivityPromptEditor } from '../CustomGamePackPromptEditor';
import { UGCTextDiff, useOpenUGCDiffModal } from '../design/UGCDiffModal';
import {
  type ActivityEditorProps,
  type ActivityGenAIRunRequest,
} from '../types';
import { generateBlock, log, UGCUtils, useAbortableBlockGenAI } from '../utils';

const parseSchema = teamIntroOpeningScriptSchema.parse;

async function runAI(
  packId: string,
  block: TitleBlockV2,
  prompt: string,
  signal?: AbortSignal | null
): Promise<ReturnType<typeof parseSchema>> {
  const q = new TagQuery(block.fields.ttsScripts);
  const script = q.selectFirst(['introduction'])?.script;
  const variables: TeamIntroOpeningScriptVariable = {
    currentScript: script ?? '',
  };
  try {
    const resp = await generateBlock(
      packId,
      {
        blockId: block.id,
        userPrompt: prompt,
        variables,
        maxWaitSecs: 60,
      },
      { signal }
    );
    log.info('generate block response', {
      data: resp.data,
      packId,
      blockId: block.id,
      blockType: block.type,
    });
    for (const step of resp.data.runSteps) {
      if (UGCUtils.VaildRunStep(step)) {
        const fn = step.stepDetails.tool_calls[0].function;
        return UGCUtils.ParseFunctionCall(packId, block, fn, parseSchema);
      }
    }
  } catch (e) {
    if (isFetchErrorCode(e, 408)) {
      log.error('generate block timeout', err2s(e), {
        packId,
        blockId: block.id,
        blockType: block.type,
      });
      return { openingScript: '' };
    }
    throw e;
  }

  throw new Error('No valid run step found');
}

export async function teamIntroGenAIAction(
  req: ActivityGenAIRunRequest<TitleBlockV2>
) {
  const { packId, block, prompt, signal } = req;
  const data = await runAI(packId, block, prompt, signal);
  if (!data.openingScript) return;

  const card = block.fields.cards?.find(
    (card) => card.teamIntroEnabled === true
  );
  const tags = ['introduction'];
  if (card?.id) tags.push(card.id);

  const updater = new TTSScriptsUpdater(async (field, value) => {
    await apiService.block.updateFieldV2(block, field, value);
  }, block);

  const ttsScript = updater.getSingleTTSScript(tags);
  const nextScript = ttsScript
    ? { ...ttsScript, script: data.openingScript }
    : {
        id: uuid4(),
        script: data.openingScript,
        tags,
      };
  await updater.handleTTSScriptUpdate(nextScript);
}

export function TeamIntroActivityEditor(
  props: ActivityEditorProps<TitleBlockV2>
): JSX.Element | null {
  const { packId, block, asset } = props;
  const { updateField } = useEditor(props);
  const updater = useTTSScriptsUpdater(updateField, props.block);
  const [script, setScript] = useState(
    updater.getSingleTTSScript(['introduction'])?.script
  );
  const card = block.fields.cards?.find(
    (card) => card.teamIntroEnabled === true
  );

  const url = MediaUtils.PickMediaUrl(fromMediaDTO(asset.media), {
    priority: ImagePickPriorityHighToLow,
  });

  const updateScript = (script: string) => {
    const tags = ['introduction'];
    if (card?.id) tags.push(card.id);
    setScript(script);
    updater.handleTTSScriptUpdate({
      tags,
      script,
    });
  };

  const openDiffModal = useOpenUGCDiffModal();

  const { generate, abort, state, error } = useAbortableBlockGenAI(
    async (prompt: string, signal: AbortSignal) => {
      props.analytics.trackCustomGameAIPrompt({
        packId: packId,
        blockId: block.id,
        blockType: block.type,
        prompt,
      });
      const data = await runAI(packId, block, prompt, signal);
      if (script) {
        const confirm = await openDiffModal({
          children: (
            <UGCTextDiff oldValue={script} newValue={data.openingScript} />
          ),
        });
        if (confirm.result !== 'confirmed') return false;
      }
      updateScript(data.openingScript);
      return true;
    }
  );

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full h-full relative'>
        {url && (
          <img
            src={url}
            alt='background'
            className='-z-1 absolute top-0 left-1/2 transform -translate-x-1/2 w-4/5'
          />
        )}
        <div className='w-148 h-65 absolute left-1/2 transfrom -translate-x-1/2 bottom-20'>
          <MultilineCardTTSEditor
            label='Host Opening Script'
            settings={block.fields.ttsOptions?.at(0)}
            value={script}
            onBeforeRender={async (script) => {
              if (!script) return null;
              const variables = makeExampleVariableRegistrySnapshot();
              return (await variables.render(script)).script;
            }}
            onChange={(v) => {
              updateScript(v);
              props.analytics.trackTeamIntroScriptEdited({
                blockId: block.id,
                blockType: block.type,
                script: v,
              });
            }}
          />
        </div>
      </div>
      <UGCActivityPromptEditor
        enabled={!!props.enableAI}
        onSubmit={generate}
        onAbort={abort}
        isSubmitting={state.isRunning}
        ctxLabel={`Editing ${asset.primaryText}`}
        error={error}
        enableThinkingHint
        placeholder='What should the host say as the opening?'
      />
    </div>
  );
}
