import { useMemo, useState } from 'react';

import { type SpotlightBlock } from '@lp-lib/game';
import { celebrationWinnerScriptSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/celebrationWinnerScript';
import { type CelebrationWinnerScriptVariable } from '@lp-lib/shared-schema/src/ai/variables/types/celebrationWinnerScript';

import { apiService } from '../../../../services/api-service';
import { isFetchErrorCode } from '../../../../services/public';
import { fromMediaDTO } from '../../../../utils/api-dto';
import { err2s } from '../../../../utils/common';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../utils/media';
import { MultilineCardTTSEditor } from '../../../VoiceOver/MultilineCardTTSEditor';
import { VoiceOverUtils } from '../../../VoiceOver/utils';
import { exampleRuntimeState } from '../../../VoiceOver/VoiceOverGenerator';
import { useEditor } from '../../Blocks/Common/Editor/EditorUtilities';
import { UGCActivityPromptEditor } from '../CustomGamePackPromptEditor';
import { UGCTextDiff, useOpenUGCDiffModal } from '../design/UGCDiffModal';
import {
  type ActivityEditorProps,
  type ActivityGenAIRunRequest,
} from '../types';
import { generateBlock, log, UGCUtils, useAbortableBlockGenAI } from '../utils';

const parseSchema = celebrationWinnerScriptSchema.parse;

async function runAI(
  packId: string,
  block: SpotlightBlock,
  prompt: string,
  signal?: AbortSignal | null
): Promise<ReturnType<typeof parseSchema>> {
  const variables: CelebrationWinnerScriptVariable = {
    currentScript: block.fields.voiceOver?.runtime?.script ?? '',
  };
  try {
    const resp = await generateBlock(
      packId,
      {
        blockId: block.id,
        userPrompt: prompt,
        variables,
        maxWaitSecs: 60,
      },
      { signal }
    );
    log.info('generate block response', {
      data: resp.data,
      packId,
      blockId: block.id,
      blockType: block.type,
    });
    for (const step of resp.data.runSteps) {
      if (UGCUtils.VaildRunStep(step)) {
        const fn = step.stepDetails.tool_calls[0].function;
        return UGCUtils.ParseFunctionCall(packId, block, fn, parseSchema);
      }
    }
  } catch (e) {
    if (isFetchErrorCode(e, 408)) {
      log.error('generate block timeout', err2s(e), {
        packId,
        blockId: block.id,
        blockType: block.type,
      });
      return { celebrationWinnerScript: '' };
    }
    throw e;
  }

  throw new Error('No valid run step found');
}

export async function winnerCelebrationGenAIAction(
  req: ActivityGenAIRunRequest<SpotlightBlock>
) {
  const { packId, block, prompt, signal } = req;
  if (!block.fields.voiceOver?.runtime) return;
  const data = await runAI(packId, block, prompt, signal);
  if (!data.celebrationWinnerScript) return;
  await apiService.block.updateFieldV2(block, 'voiceOver', {
    ...block.fields.voiceOver,
    runtime: {
      ...block.fields.voiceOver?.runtime,
      script: data.celebrationWinnerScript,
    },
  });
}

export function WinnerCelebrationActivityEditor(
  props: ActivityEditorProps<SpotlightBlock>
): JSX.Element | null {
  const { packId, block, asset } = props;
  const { updateField } = useEditor(props);
  const [script, setScript] = useState(block.fields.voiceOver?.runtime?.script);

  const updateScript = async (script: string) => {
    setScript(script);
    if (!block.fields.voiceOver?.runtime) return;
    await updateField('voiceOver', {
      ...block.fields.voiceOver,
      runtime: {
        ...block.fields.voiceOver?.runtime,
        script,
      },
    });
  };

  const url = MediaUtils.PickMediaUrl(fromMediaDTO(asset.media), {
    priority: ImagePickPriorityHighToLow,
  });

  const ttsSettings = useMemo(
    () => VoiceOverUtils.AsTTSSettings(block.fields.voiceOver?.runtime),
    [block.fields.voiceOver?.runtime]
  );

  const disabled = !block.fields.voiceOver?.runtime;

  const render = async (script: string) => {
    if (!block.fields.voiceOver?.runtime)
      throw new Error('No runtime voiceOver');
    return apiService.tts.renderRuntimeSample({
      generatorId: block.fields.voiceOver?.runtime.generatorId,
      voiceId: block.fields.voiceOver?.runtime.voiceId,
      script,
      settings: block.fields.voiceOver?.runtime.settings,
      gameState: exampleRuntimeState,
    });
  };

  const openDiffModal = useOpenUGCDiffModal();

  const { generate, abort, state, error } = useAbortableBlockGenAI(
    async (prompt: string, signal: AbortSignal) => {
      props.analytics.trackCustomGameAIPrompt({
        packId: packId,
        blockId: block.id,
        blockType: block.type,
        prompt,
      });
      const data = await runAI(packId, block, prompt, signal);
      if (script) {
        const confirm = await openDiffModal({
          children: (
            <UGCTextDiff
              oldValue={script}
              newValue={data.celebrationWinnerScript}
            />
          ),
        });
        if (confirm.result !== 'confirmed') return false;
      }
      await updateScript(data.celebrationWinnerScript);
      return true;
    }
  );

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full h-full relative'>
        {url && (
          <img
            src={url}
            alt='background'
            className='-z-1 absolute top-0 left-1/2 transform -translate-x-1/2 w-4/5'
          />
        )}
        <div className='w-148 h-65 absolute left-1/2 transfrom -translate-x-1/2 bottom-20'>
          <MultilineCardTTSEditor
            label={
              <div className='flex items-center gap-1'>
                <p>Host Celebration Script</p>
                {disabled && (
                  <p className='text-2xs text-icon-gray'>
                    (Invaild runtime settings)
                  </p>
                )}
              </div>
            }
            settings={ttsSettings}
            value={script}
            onChange={async (v) => {
              await updateScript(v);
              props.analytics.trackHostCelebrationScriptEdited({
                blockId: block.id,
                blockType: block.type,
                script: v,
              });
            }}
            disabled={disabled}
            render={render}
          />
        </div>
      </div>
      <UGCActivityPromptEditor
        enabled={!!props.enableAI}
        onSubmit={generate}
        onAbort={abort}
        isSubmitting={state.isRunning}
        ctxLabel={`Editing ${asset.primaryText}`}
        error={error}
        enableThinkingHint
        placeholder='What should the host say to celebrate the winner?'
      />
    </div>
  );
}
